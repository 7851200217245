@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css);
/* Styles go here */
body{
	width: 100vw;
	height: 100vh;
	border: none;
}
#Loading{
    height:100vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
